import Vue from 'vue'
import vSelect from 'vue-select'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

if (document.querySelector('.v-pinpoint')) {
  import('@/modules/PinPoint')
    .then(({ default: component }) => {
      component.$mount('.v-pinpoint')
    })
}

if (document.querySelector('.v-report-2')) {
  import('@/modules/ReportV2')
    .then(({ default: component }) => {
      component.$mount('.v-report-2')
    })
}

if (document.querySelector('.v-report')) {
  import('@/modules/Report')
    .then(({ default: component }) => {
      component.$mount('.v-report')
    })
}
